import React from "react";

const LoadingCards = () => {
  return (
    <div class="card">
      <div class="shimmerBG media"></div>
      <div class="p-32">
        <div class="shimmerBG content-line m-t-24"></div>
        <div class="shimmerBG content-line"></div>
        <div class="shimmerBG content-line end"></div>
      </div>
    </div>
  );
};

export default LoadingCards;
